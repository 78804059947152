<!--  -->
<template>
  <div>
    <div class="mainCont"></div>
    <div class="def-container tabBtn">
      <div class="bannerTxt">
        <span class="chTxt">精准推送</span><br />
        <span class="enTxt">PRECISE PUSH</span>
      </div>
      <div
        @click="changeTab('system')"
        :class="{ clickBtn: activeName == 'system' }"
      >
        系统推荐
      </div>
      <div
        @click="changeTab('enjoy')"
        :class="{ clickBtn: activeName == 'enjoy' }"
      >
        免申即享
      </div>
      <div
        @click="changeTab('dept')"
        :class="{ clickBtn: activeName == 'dept' }"
      >
        部门推荐
      </div>
      <div
        v-show="activeName == 'system'"
        style="
          line-height: 0;
          margin-left: inherit;
          width: 260px;
          margin-top: 30px;
          height: 20px;
        "
      >
        <el-button
          type="danger"
          @click="openYh"
          style="width: 160px; height: 50px"
          >精准推送</el-button
        >
        <div
          style="font-size: 16px; color: #ff3131; width: 260px; line-height: 1"
        >
          <span>*填写企业信息将获得更匹配的政策</span>
        </div>
      </div>
    </div>
    <div class="def-container totalInfo" v-if="activeName != 'enjoy'">
      共<span class="num">{{ totleMap.qb }}</span
      >条政策项目
      <span class="other"
        >（国家级{{ totleMap.gj }}条、省级{{ totleMap.sj }}条、市级{{
          totleMap.dsj
        }}条）</span
      >
      <span
        v-show="activeName == 'system'"
        style="font-size: 16px; color: #ff3131"
        >当前匹配精确度为<span class="num">{{ totleMap.jqd }}%</span></span
      >
    </div>
    <div class="def-container" style="margin-top: 10px">
      <div v-if="activeName == 'system'">
        <div
          class="qw-list-empty def-container"
          v-if="policyInfo.data.length == 0"
        >
          <div class="qw-list-empty__inner">
            <div>
              <i class="el-icon-folder-delete"></i>
            </div>
            <div><span> 暂无数据 </span></div>
            <div class="toBaseInfoEdit" @click="toBaseInfoEdit">
              完善企业信息>>
            </div>
          </div>
        </div>
        <div v-else class="def-container" style="position: relative">
          <div
            class="box-card"
            v-for="(item, index) in policyInfo.data"
            :key="index"
          >
            <el-row class="titleRow">
              <img
                src="@/assets/images/favorites_off.png"
                width="24"
                height="24"
                alt=""
              />
              <span style="color: #333; font-weight: 700; font-size: 18px"
                >【{{ item.areaid }}】</span
              >
              <span
                class="limitWidthSpan"
                :title="item.name"
                style="color: #333; font-weight: 700; font-size: 18px"
                @click="tonewpage(item)"
                >{{ item.name }}</span
              >
              <div class="match">
                推荐指数：<span
                  v-for="item in starNum(item.ppd).star"
                  :key="item"
                  class="star"
                ></span>
                <span
                  v-for="item in starNum(item.ppd).restStar"
                  :key="item + 5"
                  class="star1"
                ></span>
              </div>
            </el-row>
            <el-row class="box-line">
              <el-col :span="2" class="box-line-title">发文单位:</el-col>
              <el-col :span="5">{{ item.deptname }}</el-col>
              <el-col :span="2" class="box-line-title">申报时间:</el-col>
              <el-col :span="15"
                >{{ moment(item.starttime).format("YYYY-MM-DD") }}至{{
                  moment(item.endtime).format("YYYY-MM-DD")
                }}
              </el-col>
            </el-row>
            <el-row class="box-line" v-if="item.tkhText">
              <el-col :span="3" class="box-line-title">资金限额:</el-col>
              <el-col :span="5">{{ item.tkhText || "" }}</el-col>
            </el-row>
            <div class="wssb">
              <div
                class="btn"
                :disabled="!item.ksbflag"
                @click="goDeclare(item)"
              >
                办事指南
              </div>
              <!--isMsjx  0是免申即享，1否 -->
              <template v-if="handleTime(item)">
                <el-button
                  v-show="item.isApply && item.isApply === '1'"
                  class="btn"
                  @click="toApply(item)"
                  style="line-height: 15px; margin-left: 10px"
                >
                  <span>{{
                    item.isMsjx && item.isMsjx == "0" ? "完善信息" : "一键申报"
                  }}</span>
                </el-button>
              </template>
              <template v-else>
                <el-button
                  disabled
                  style="line-height: 15px; margin-left: 10px"
                >
                  申报结束
                </el-button>
              </template>
            </div>
          </div>
          <div class="content-pagination">
            <el-pagination
              :total="pageInfo.total"
              :current-page.sync="pageInfo.num"
              background
              layout="total,prev,pager,next,jumper"
              @current-change="changePage($event, 'system')"
            ></el-pagination>
          </div>
        </div>
      </div>
      <div v-if="activeName == 'dept'">
        <div
          class="qw-list-empty def-container"
          v-if="policyInfo.deptPolicyData.length == 0"
        >
          <div class="qw-list-empty__inner">
            <div>
              <i class="el-icon-folder-delete"></i>
            </div>
            <div><span> 暂无数据 </span></div>
          </div>
        </div>
        <div v-else class="def-container" style="position: relative">
          <div
            class="box-card"
            v-for="(item, index) in policyInfo.deptPolicyData"
            :key="index"
          >
            <el-row class="titleRow">
              <img
                src="@/assets/images/favorites_off.png"
                width="24"
                height="24"
                alt=""
              />
              <span style="color: #333; font-weight: 700; font-size: 18px"
                >【{{ item.areaid }}】</span
              >
              <span
                class="limitWidthSpan"
                :title="item.name"
                style="color: #333; font-weight: 700; font-size: 18px"
                >{{ item.name }}</span
              >
            </el-row>
            <el-row class="box-line">
              <el-col :span="2" class="box-line-title">发文单位:</el-col>
              <el-col :span="5" :title="item.fbjg">{{
                handleFbjg(item.fbjg)
              }}</el-col>
              <el-col :span="2" class="box-line-title">申报时间:</el-col>
              <el-col :span="15"
                >{{ moment(item.starttime).format("YYYY-MM-DD") }}至{{
                  moment(item.endtime).format("YYYY-MM-DD")
                }}
              </el-col>
            </el-row>
            <el-row class="box-line" v-if="item.tkhText">
              <el-col :span="2" class="box-line-title">资金限额:</el-col>
              <el-col :span="12">{{ item.tkhText || "" }}</el-col>
            </el-row>
            <div class="wssb">
              <div
                class="btn"
                :disabled="!item.ksbflag"
                @click="goDeclare(item)"
              >
                办事指南
              </div>
              <template v-if="handleTime(item)">
                <el-button
                  v-show="item.isApply && item.isApply === '1'"
                  class="btn"
                  @click="toApply(item)"
                  style="line-height: 15px; margin-left: 10px"
                >
                  <span>{{
                    item.isMsjx && item.isMsjx == "0" ? "完善信息" : "一键申报"
                  }}</span>
                </el-button>
              </template>
              <template v-else>
                <el-button
                  disabled
                  style="line-height: 15px; margin-left: 10px"
                >
                  申报到期
                </el-button>
              </template>
            </div>
          </div>
          <div class="content-pagination">
            <el-pagination
              :total="pageInfoDept.total"
              :current-page.sync="pageInfoDept.num"
              background
              layout="total,prev,pager,next,jumper"
              @current-change="changePage($event, 'dept')"
            ></el-pagination>
          </div>
        </div>
      </div>
      <enjoyWithoutApply v-if="activeName == 'enjoy'"></enjoyWithoutApply>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { mapGetters } from "vuex";
import enjoyWithoutApply from "@/views/enjoyWithoutApply/index";
export default {
  data() {
    return {
      activeName: "system",
      // 政策信息
      policyInfo: {
        data: [],
        deptPolicyData: [],
      },
      pageInfo: {
        total: 0,
        num: 1,
        size: 6,
      },
      pageInfoDept: {
        total: 0,
        num: 1,
        size: 6,
      },
      total: "",
      data: {},
      json: {},
      form: {},
      totleMap: {
        qb: 0,
        sj: 0,
        dsj: 0,
        jqd: 0,
      },
      moment,
    };
  },
  components: { enjoyWithoutApply },
  created() {
    this.createdInit();
  },
  mounted() {},
  computed: {
    ...mapGetters({
      userInfo: "getUserInfo",
      getDictLabel: "dictionary/getDictLabel",
      storeguideId: "getCurrentGuideId",
    }),
  },
  methods: {
    // 处理申报时间
    handleTime(item) {
      const NOW = new Date();
      // 未开始/剩余天数/已过期
      let startTime = new Date(item.starttime || item.startTime);
      if (NOW < startTime) return false;

      let endTime = new Date(item.endtime);
      if (endTime < NOW) return false;
      return true;
    },
    //跳转到用户中心
    openYh() {
      sessionStorage.setItem("openYh", "true");
      this.$router.push("/workplace/setting");
    },
    toApply(item) {
      if (
        item.sxid == "e3d1fa8f6e5c4620ab28ba63d1b272fb" ||
        item.sxid == "7d7cd03229954c269756ef4e4df06a5c"
      ) {
        // 测试用的sxid
        // if(item.sxid=='31a2d9fe9d674573905821948c55dfc0'){
        // 只有事项id为这两个的时候，页面显示的字段跟其他不一样
        sessionStorage.setItem("isSpecialProject", "true");
      } else {
        sessionStorage.setItem("isSpecialProject", "false");
      }
      let templateInfo = {
        templateFlag: item.templateFlag,
        templateFileId: item.templateFileId,
        templateFileName: item.templateFileName,
      };
      templateInfo = JSON.stringify(templateInfo);
      this.$store.commit("updateCurrentGuideId", item.sxid);
      this.$router.push({
        name: "policyDeclaration",
        query: {
          policyId: this.storeguideId,
          templateInfo: templateInfo,
        },
      });
    },
    handleFbjg(val) {
      if (val && val.length > 13) {
        return val.substr(0, 13) + "...";
      }
      return val || "";
    },
    changeTab(param) {
      this.activeName = param;
      switch (param) {
        case "system":
          this.getPolicyBySystem();
          break;
        case "dept":
          this.getDeptPolicy();
          break;
        default:
          break;
      }
    },
    createdInit() {
      let activeName = sessionStorage.getItem("matchActiveName");
      if (activeName) {
        this.activeName = sessionStorage.getItem("matchActiveName");
        if (this.activeName == "system") {
          this.getPolicyBySystem(1, 6);
        } else {
          this.getDeptPolicy();
        }
      } else {
        this.getPolicyBySystem();
        this.getDeptPolicy();
      }
    },
    // 推荐星星个数
    starNum(num) {
      let param = 0;
      if (num) {
        param = parseFloat(num);
      }
      let objStar = {
        star: 0,
        restStar: 0,
      };
      if (param > 0 && param <= 20) {
        objStar.star = 1;
        objStar.restStar = 4;
      }
      if (param > 20 && param <= 40) {
        objStar.star = 2;
        objStar.restStar = 3;
      }
      if (param > 40 && param <= 60) {
        objStar.star = 3;
        objStar.restStar = 2;
      }
      if (param > 60 && param <= 80) {
        objStar.star = 4;
        objStar.restStar = 1;
      }
      if (param > 80 && param <= 100) {
        objStar.star = 5;
        objStar.restStar = 0;
      }
      // 用函数返回局部变量，使用data里的变量会造成死循环
      return objStar;
    },
    toBaseInfoEdit() {
      this.$router.push("/workplace/setting");
    },
    // 部门推荐
    getDeptPolicy() {
      let url = "/dev-api/policy/evaluation/deptRecommend";
      let param = {
        tyshxydm: this.userInfo.tyshxydm,
        pageNum: this.pageInfoDept.num,
        pageSize: this.pageInfoDept.size,
      };
      this.$httpApi
        .post(url, param)
        .then((res) => {
          if (res.code == 200) {
            this.pageInfoDept.total = res.data.pageInfo.total;
            let numObj = {
              dsj: res.data.dsj,
              gj: res.data.gj,
              sj: res.data.sj,
              qb: res.data.pageInfo.total,
              zzxe: res.data.zzxe,
            };
            this.totleMap = Object.assign({}, numObj);
            let temp = [];
            res.data.pageInfo.list.forEach((item) => {
              var aData = new Date();
              var year = aData.getFullYear(); //年
              var month = aData.getMonth(); //月
              if (month < 9) {
                month = "0" + (month + 1);
              }
              var day = aData.getDate(); //日
              let ndate = year + "-" + month + "-" + day;
              let startTime = item.starttime.substr(0, 10);
              let endTime = item.endtime.substr(0, 10); //年
              if (startTime < ndate && ndate < endTime) {
                item["ksbflag"] = true;
              } else {
                item["ksbflag"] = false;
              }
              temp.push(item);
            });
            this.policyInfo.deptPolicyData = temp;
          }
        })
        .catch();
    },
    goDeclare(item) {
      this.$store.commit("updateCurrentGuideId", item.sxid);
      this.$router.push({
        path: "/guideSuqiu",
        query: {
          name: item.name,
          itemType: "2",
          isAdded: item.isAdded,
        },
      });
    },
    tonewpage(item) {
      this.$router.push({
        name: "policyMatchDetails",
        params: {
          listData: item,
        },
      });
    },
    changePage(page, param) {
      if (param == "system") {
        this.pageInfo.num = page;
        this.getPolicyBySystem();
      }
      if (param == "dept") {
        this.pageInfoDept.num = page;
        this.getDeptPolicy();
      }
    },
    // 系统推荐
    getPolicyBySystem(pageNum, pageSize) {
      let that = this;
      pageNum || this.pageInfo.num;
      pageSize || this.pageInfo.size;
      let url = "/dev-api/policy/evaluation/recommend";
      let params = {};
      params = {
        shxydm: this.userInfo.tyshxydm,
        pageNum: this.pageInfo.num,
        pageSize: this.pageInfo.size,
      };
      this.$httpApi
        .get(url, params)
        .then((res) => {
          if (res.code == 200) {
            that.pageInfo.total = res.data.list.total;
            let numObj = {
              dsj: res.data.dsj,
              gj: res.data.gj,
              sj: res.data.sj,
              qb: res.data.list.total,
              zzxe: res.data.zzxe,
              jqd: parseFloat(res.data.jqd).toFixed(2),
            };
            that.totleMap = Object.assign({}, numObj);
            /**
             * 20210628
             * 2个人写了2个接口，返回的数据格式不一样
             * 用map同步一下
             */
            that.policyInfo.data = (res.data.list.list || []).map((cv) => {
              var aData = new Date();
              var year = aData.getFullYear(); //年
              var month = aData.getMonth(); //月
              if (month < 9) {
                month = "0" + (month + 1);
              }
              var day = aData.getDate(); //日
              let ndate = year + "-" + month + "-" + day;
              let startTime = cv.startTime.substr(0, 10);
              let endTime = cv.endTime.substr(0, 10); //年
              if (startTime < ndate && ndate < endTime) {
                cv.ksbflag = true;
              } else {
                cv.ksbflag = false;
              }
              return {
                isApply: cv.isApply,
                xmId: cv.xmId,
                name: cv.xmName,
                deptname: cv.deptName,
                starttime: cv.startTime,
                endtime: cv.endTime,
                endtime: cv.endTime,
                ksbflag: cv.ksbflag,
                policyName: cv.policyName,
                policyId: cv.policyId,
                sxid: cv.xmId,
                star: cv.star,
                areaid: cv.areaid,
                zzxe: cv.zzxe,
                ppd: cv.ppd,
                sysCategoryName: cv.sysCategoryName,
                enterpriseSizeName: cv.enterpriseSizeName,
                enterpriseAttributesName: cv.enterpriseAttributesName,
                growthCycleName: cv.growthCycleName,
                sysEnterTypeName: cv.sysEnterTypeName,
                sysEnterpriseRegistertypeName: cv.sysEnterpriseRegistertypeName,
                requireRegist: cv.requireRegist,
                paymentPlace: cv.paymentPlace,
                mainbusinessIncome: cv.mainbusinessIncome,
                lastIncomeTax: cv.lastIncomeTax,
                json: cv.json,
                sysCategoryPp: cv.sysCategoryPp,
                enterpriseSizePp: cv.enterpriseSizePp,
                enterpriseAttributesPp: cv.enterpriseAttributesPp,
                growthCyclePp: cv.growthCyclePp,
                sysEnterTypePp: cv.sysEnterTypePp,
                requireRegistPp: cv.requireRegistPp,
                paymentPlacePp: cv.paymentPlacePp,
                sysEnterpriseRegistertypePp: cv.sysEnterpriseRegistertypePp,
                mainbusinessIncomePp: cv.mainbusinessIncomePp,
                lastIncomeTaxPp: cv.lastIncomeTaxPp,
                ppTotle: cv.ppTotle,
                ppNum: cv.ppNum,
                shxydm: cv.shxydm,
                zzbzData: [{ zzqx: cv.supportStandard }],
                sysCategoryEnterpriseName: cv.sysCategoryEnterpriseName,
                enterpriseSizeEnterpriseName: cv.enterpriseSizeEnterpriseName,
                enterpriseAttributesEnterpriseName:
                  cv.enterpriseAttributesEnterpriseName,
                growthCycleEnterpriseName: cv.growthCycleEnterpriseName,
                sysEnterTypeEnterpriseName: cv.sysEnterTypeEnterpriseName,
                sysEnterpriseRegistertypeEnterpriseName:
                  cv.sysEnterpriseRegistertypeEnterpriseName,
              };
            });
          }
        })
        .catch((err) => {
          console.log("getPolicyBySystem error:", err);
        });
    },
  },
};
</script>
<style lang='scss' scoped>
.limitWidthSpan {
  width: 70%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.titleRow {
  margin-bottom: 8px;
  display: flex;
  align-items: center;
}
.content-pagination {
  height: 50px;
  text-align: center;
  margin: 20px auto;
}
::v-deep {
  .el-tabs__item {
    font-size: 18px;
  }
  .el-tabs__item.is-active,
  .el-tabs__item:hover {
    color: red !important;
  }
}
.wssb {
  position: absolute;
  top: 60px;
  right: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: #fff;
  z-index: 2;
  .btn {
    width: 120px;
    height: 40px;
    border-radius: 4px;
    background: #fff;
    border: 1px solid #0e97ff;
    text-align: center;
    line-height: 40px;
    font-size: 16px;
    color: #0e97ff;
    transition: background-color 0.3s, color 0.3s;
    &:hover {
      color: #fff;
      background: #0e97ff;
    }
  }
}
.mainCont {
  width: 100%;
  height: 200px;
  background: url("~@/assets/deyangImg/jztuisong.png") no-repeat center / cover;
  display: flex;
  align-items: center;
}
.tabBtn {
  display: flex;
  position: relative;
  div {
    cursor: pointer;
    width: 186px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    margin: 14px 0 28px;
    background: #fff;
    font-size: 22px;
  }
  .clickBtn {
    background: #03b1ff;
    font-weight: 700;
    color: #fff;
  }
  .bannerTxt {
    position: absolute;
    top: -163px;
    left: 25px;
    color: #fff !important;
    background: transparent;
    .chTxt {
      font-size: 40px;
      font-weight: 700;
      letter-spacing: 5px;
    }
    .enTxt {
      font-size: 22px;
      font-weight: 500;
      word-spacing: 5px;
      letter-spacing: 2px;
    }
  }
}
.totalInfo {
  font-size: 22px;
  color: #666666;
  margin-bottom: 12px;
  .num {
    color: #fd860f;
    margin: 0 5px;
  }
  .other {
    font-size: 16px;
    color: #bbbbbb;
  }
}
.report-container {
  background-color: #f0f2f5;
  .report-container__filter {
    @media screen and (min-width: 1366px) {
      max-width: 1182px;
    }
    @media screen and (min-width: 1600px) {
      max-width: 1340px;
    }
    @media screen and (min-width: 1920px) {
      max-width: 1470px;
    }
    margin: 0 auto;
    display: flex;
    align-items: center;
    position: relative;
    .infoBtn {
      position: absolute;
      right: 17px;
    }
    .report-container__filter__item {
      width: 126px;
      height: 53px;
      display: flex;
      align-items: center;
      font-size: 16px;
      color: #333;
      cursor: pointer;
      text-align: center;
    }
    .report-container__filter__item.is-active {
      font-size: 18px;
      color: #0069e8;
    }
  }
}
.qw-list-empty {
  width: 100%;
  min-height: 200px;
  height: 20vh;
  position: relative;
  background: #fff;
  .qw-list-empty__inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #989899;
    span {
      font-size: 18px;
      line-height: 24px;
    }
    i {
      font-size: 36px;
      line-height: 80px;
    }
    .toBaseInfoEdit {
      cursor: pointer;
      color: #23caf2;
      text-decoration: underline;
    }
  }
}
.box-card {
  margin: 20px auto;
  margin-left: -20px;
  min-height: 120px;
  position: relative;
  background: #f9f9f9;
  cursor: pointer;
  border: 1px solid #fff;
  border-radius: 10px;
  padding: 15px 8px;
  padding-left: 20px;
  .box-line {
    margin: 12px 0;
    display: flex;
    font-size: 16px;
    color: #666;
    line-height: 20px;
    .box-line-title {
      font-weight: 600;
    }
  }
}
.match {
  cursor: pointer;
  font-size: 14px;
  border-radius: 4px;
  box-sizing: border-box;
  white-space: nowrap;
  height: 24px;
  padding: 0 8px;
  line-height: 22px;
  color: #fdc00f;
  margin-right: 10%;
  position: absolute;
  top: 8px;
  right: -110px;
  display: flex;
  align-items: center;
  .star,
  .star1 {
    display: inline-block;
    width: 14px;
    height: 14px;
    background: url("~@/assets/images/star1_a.png") center / 100% 100% no-repeat;
  }
  .star1 {
    background: url("~@/assets/images/star1.png") center / 100% 100% no-repeat;
  }
}
// .zct-policy-item-comp__rect:before {
//   content: "";
//   width: 0;
//   height: 0;
//   border-bottom: 34px solid #fff;
//   border-right: 14px solid transparent;
//   position: absolute;
//   bottom: 0;
//   left: 0;
// }
.zct-policy-item-comp__rect.is-grey {
  background-color: #c7d3dc;
}
.zct-policy-item-comp__rect.is-green {
  background-color: #55df84;
}
.zct-policy-item-comp__rect {
  width: 50px;
  height: 100%;
  position: absolute;
  left: -50px;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: #fff;
  z-index: 2;
  span {
    display: inline-block;
    width: 20px;
  }
}
::v-deep .el-button[disabled="disabled"] {
  background-color: #fff !important;
}
</style>
